import { ModalName } from "@/components/Modal/modal-name";
import { useWeb3Store } from "@/store/web3";
import { connectWallet, inited } from "@/web3";
import { watchOnce } from "@vueuse/shared";
import { computed, watch } from "vue";
import { useModal } from "./useModal";

export const usePageWithWallet = ({ onInit, onWalletChange } = {}) => {
  const web3Store = useWeb3Store();
  const checkNetwork = () => {
    if (web3Store.isWrongNetwork && web3Store.isConnected) {
      useModal().open({
        name: ModalName.WrongNetworkModal,
        closeOnOverlay: false,
      });
    } else {
      useModal().close(ModalName.WrongNetworkModal);
    }
  };
  const isConnected = computed(() => web3Store.isConnected);

  const init = () => {
    checkNetwork();
    onInit && onInit(isConnected.value);
  };
  if (inited.value) {
    init();
  } else {
    watchOnce(inited, () => {
      init();
    });
  }

  const connect = () => {
    return connectWallet();
  };
  watch(
    () => web3Store.walletAddress,
    () => {
      if (!inited.value) return;
      onWalletChange && onWalletChange(isConnected.value);
    }
  );
  watch(() => web3Store.isWrongNetwork, checkNetwork);
  return { isConnected, connect };
};
